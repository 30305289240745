<template>
  <el-dialog class="noheader-dialog" width="280px" :show-close="false" :visible.sync="areaDialogVisible" :before-close="handleClose">
    <div class="title">切换地区</div>
    <div class="content">
      <el-select class="list" placeholder="年级" v-model="gradeId" @change="getProvince">
        <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select class="list" placeholder="省" v-model="provinceId" @change="getCity">
        <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select class="list" placeholder="市" v-model="cityId" v-show="gradeId !== 8">
        <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="footer">
      <el-button class="btn" type="primary" @click="submit">保存</el-button>
      <el-button class="btn" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AreaDialog',
  props: ['areaDialogVisible'],
  data () {
    return {
      gradeList: [{id: 7, name: '初中'}, {id: 8, name: '高中'}],
      provinceList: [],
      cityList: [],
      gradeId: '',
      provinceId: '',
      cityId: ''
    }
  },
  methods: {
    getProvince () {
      this.$axios({
        method: 'post',
        url: this.$urls.cityList,
        data: {
          gradeTypeId: this.gradeId
        }
      })
        .then((response) => {
          if (response.state === '11') {
            this.provinceList = response.data
            this.provinceId = ''
            this.cityList = []
            this.cityId = ''
          }
        })
    },
    // 获取市
    getCity () {
      this.$axios({
        method: 'post',
        url: this.$urls.cityGet,
        data: {
          pid: this.provinceId
        }
      })
        .then((response) => {
          if (response.state === '11') {
            this.cityList = response.data
            this.cityId = ''
          }
        })
    },
    handleClose () {
      this.$emit('areaDialogClose')
    },
    submit () {
      const { gradeId } = this
      const { provinceId } = this
      if(!gradeId){
        this.$message({
          type: 'error',
          message: '年级不能为空'
        })
        return
      }

      if(!provinceId){
        this.$message({
          type: 'error',
          message: '省不能为空'
        })
        return
      }
      if(gradeId !== 8 && !this.cityId){
        this.$message({
          type: 'error',
          message: '市不能为空'
        })
        return
      }
      const cityId = this.cityId === '' ? -1 : this.cityId
      this.$emit('areaSubmit', gradeId, provinceId, cityId)
    }
  }
}
</script>

<style scoped lang="scss">
  .title {
    @include font(15px, #999999, center);
    margin-bottom: 20px;
  }
  .content {
    margin-bottom: 20px;
    .el-select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .btn {
      width: 91px;
      height: 30px;
    }
  }
</style>
