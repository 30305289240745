<template>
    <el-dialog class="noheader-dialog" width="286px" :show-close="false" :visible.sync="pswDialogVisible"
               :before-close="handleClose">
        <div class="title">更改密码</div>
        <div class="content">
            <el-input
                    class="input"
                    placeholder="请输入原密码"
                    v-model="oldPsw"
                    maxlength="25"
                    show-password
                    clearable
            ></el-input>
            <el-input
                    class="input"
                    placeholder="请输入新密码"
                    maxlength="25"
                    v-model="newPsw"
                    show-password
                    clearable
            ></el-input>
        </div>
        <div class="footer">
            <el-button class="btn" type="primary" :disabled="disabled" @click="submit">保存</el-button>
            <el-button class="btn" @click="handleClose">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'PswDialog',
        props: ['pswDialogVisible'],
        data() {
            return {
                oldPsw: '',
                newPsw: ''
            }
        },
        computed: {
            disabled: function () {
                if (this.oldPsw !== '' && this.newPsw !== '') return false
                return 'disabled'
            }
        },
        methods: {
            submit() {
                const {oldPsw,newPsw} = this
                if (oldPsw.trim() === '' || newPsw.trim() === '') return
                if (!this.common.validatePassword(newPsw)) {
                    this.$message.warning('密码必须包含字母、数字和特殊字符，并在8~25位之间！')
                    return
                }
                this.$emit('pswSubmit', oldPsw, newPsw)
            },
            handleClose() {
                this.$emit('pswDialogClose')
            }
        }
    }
</script>

<style scoped lang="scss">
    .title {
        @include font(15px, #999999, center);
        margin-bottom: 20px;
    }

    .content {
        margin-bottom: 20px;

        .el-input {
            margin-bottom: 10px;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;

        .btn {
            width: 91px;
            height: 30px;
        }
    }
</style>
