<template>
  <article v-loading="loading">
    <Header></Header>
    <section class="manage-container">
      <RouterNav :name="name"></RouterNav>
      <h1 class="title">账号管理</h1>
      <div class="content">
        <div class="head">
          <img :src="head" alt="head" v-if="head">
        </div>
        <div class="column">
          <div class="label">姓名</div>
          <div class="right">
            <div class="value">{{userName}}</div>
            <div class="btn" @click="rename">修改</div>
          </div>
        </div>
        <div class="column">
          <div class="label">地区</div>
          <div class="right">
            <div class="value">{{areaName}}</div>
            <div class="btn" @click="areaChange">切换</div>
          </div>
        </div>
        <div class="column">
          <div class="label">学校</div>
          <div class="right">
            <div class="value">{{schoolName}}</div>
          </div>
        </div>
        <div class="column">
          <div class="label">手机号</div>
          <div class="right">
            <div class="value">手机号码为教师端登录账号，当前手机号为：{{phone}}</div>
            <div class="btn" @click="phoneChange">更换</div>
          </div>
        </div>
        <div class="column">
          <div class="label">密码</div>
          <div class="right">
            <div class="value">为了账号安全，请定期修改密码</div>
            <div class="btn" @click="pswChange">修改</div>
          </div>
        </div>
      </div>
    </section>

    <NameDialog
        :nameDialogVisible="nameDialogVisible"
        :name="userName"
        @nameDialogClose="nameDialogClose"
        @updateName="updateName"
    ></NameDialog>

    <AreaDialog
        :areaDialogVisible="areaDialogVisible"
        @areaDialogClose="areaDialogClose"
        @areaSubmit="areaSubmit"
    ></AreaDialog>

    <PhoneDialog
        :phoneDialogVisible="phoneDialogVisible"
        @phoneDialogClose="phoneDialogClose"
        @phoneSubmit="phoneSubmit"
    ></PhoneDialog>

    <PswDialog
        :pswDialogVisible="pswDialogVisible"
        @pswDialogClose="pswDialogClose"
        @pswSubmit="pswSubmit"
    ></PswDialog>
  </article>
</template>

<script>
  import Header from '@/layout/components/header.vue'
  import RouterNav from '@/components/RouterNav.vue'
  import NameDialog from './components/NameDialog.vue'
  import AreaDialog from './components/AreaDialog.vue'
  import PhoneDialog from './components/PhoneDialog.vue'
  import PswDialog from './components/PswDialog.vue'

  export default {
    name: 'manage',
    components: {
      Header,
      RouterNav,
      NameDialog,
      AreaDialog,
      PhoneDialog,
      PswDialog
    },
    data() {
      return {
        name: '账号管理',
        loading: false,
        head: null,
        userName: '',
        areaName: '',
        schoolName: '',
        phone: '',
        nameDialogVisible: false,
        areaDialogVisible: false,
        phoneDialogVisible: false,
        pswDialogVisible: false
      }
    },
    created() {
      this.teacherGet()
    },
    methods: {
      updateName(name) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.teacherUpdate,
          data: {
            name
          }
        }).then((res) => {
          this.loading = false
          if (res.state === '11') {
            this.userName = name
            this.nameDialogVisible = false
            this.$store.commit('updateUserInfo', { teacherName: name })
            this.$message({
              type: 'success',
              message: '更新教师信息成功'
            })
          }
        }).catch(() => {
          this.loading = false
        })
      },
      nameDialogClose() {
        this.nameDialogVisible = false
      },
      rename() {
        this.nameDialogVisible = true
      },
      areaChange() {
        this.areaDialogVisible = true
      },
      areaDialogClose() {
        this.areaDialogVisible = false
      },
      phoneChange() {
        this.phoneDialogVisible = true
      },
      phoneDialogClose() {
        this.phoneDialogVisible = false
      },
      phoneSubmit(phone, code) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.updateNewPhone,
          data: {
            phone,
            code
          }
        }).then((res) => {
          this.loading = false
          if (res.state === '11') {
            this.phone = phone
            this.phoneDialogVisible = false
            this.$store.commit('updateUserInfo', { phone: phone })
            this.$message({
              type: 'success',
              message: '更新教师信息成功'
            })
          }
        }).catch(() => {
          this.loading = false
        })
      },
      pswChange() {
        this.pswDialogVisible = true
      },
      pswDialogClose() {
        this.pswDialogVisible = false
      },
      pswSubmit(oldPassword, password) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.updateNewPassword,
          data: {
            oldPassword: this.$md5(oldPassword),
            password: this.$md5(password)
          }
        }).then((res) => {
          this.loading = false
          if (res.state === '11') {
            this.pswDialogVisible = false
            this.$message({
              type: 'success',
              message: '更改密码成功'
            })
          }
        }).catch(() => {
          this.loading = false
        })
      },
      areaSubmit(gradeTypeId, provinceId, cityId) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.updateArea,
          data: {
            provinceId,
            cityId,
            gradeTypeId
          }
        }).then((res) => {
          this.loading = false
          if (res.state === '11') {
            sessionStorage.setItem('gradeTypeId', gradeTypeId)
            sessionStorage.setItem('provinceId', provinceId)
            sessionStorage.setItem('cityId', cityId)
            this.$store.commit('updateUserInfo', { gradeTypeId, provinceId, cityId })
            this.areaDialogVisible = false
            // 向父页面传递信息
            window.parent && window.parent.postMessage({
              type: 'update-teacher-info',
              gradeTypeId,
              provinceId,
              cityId
            }, '*')
            // 向打开当前窗口的那个窗口传递信息
            window.opener && window.opener.postMessage({
              type: 'update-teacher-info',
              gradeTypeId,
              provinceId,
              cityId
            }, '*')
            console.log('传递给其他页面的信息', {
              type: 'update-teacher-info',
              gradeTypeId,
              provinceId,
              cityId
            })
            this.$message({
              type: 'success',
              message: '更新教师信息成功'
            })
            this.teacherGet()
          }
        }).catch(() => {
          this.loading = false
        })
      },
      teacherGet() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.teacherGet
        }).then((res) => {
          this.loading = false
          if (res.state === '11') {
            const {data} = res
            this.head = data.wxAvatarUrl
            this.userName = data.name
            this.areaName = data.areaName
            this.schoolName = data.schoolName
            this.phone = data.phone
          }
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .manage-container {
    max-width: 1100px;
    margin: 0 auto;

    .title {
      height: 84px;
      line-height: 84px;
      padding-left: 28px;
      font-size: 20px;
    }

    .content {
      background-color: #fff;
      height: calc(100vh - 205px);
      padding: 40px 28px;
      overflow: auto;

      .head {
        background-color: #F6F6F6;
        width: 150px;
        height: 150px;
        border-radius: 75px;
        margin: 0 auto 40px;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .column {
        @include flex;
        padding: 0 28px;

        &:hover {
          background-color: #F6F6F6;
        }

        .label {
          flex-shrink: 0;
          width: 104px;
          @include font(17px, #999);
        }

        .right {
          flex-grow: 1;
          @include flex(space-between);
          height: 60px;
          border-bottom: 1px solid #F1F1F1;
          font-size: 17px;

          .btn {
            height: 30px;
            line-height: 30px;
            padding: 0 20px;
            border: 1px solid #E5E5E5;
            border-radius: 15px;
            @include font(15px, #309AF2, center);
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
