<template>
  <el-dialog class="noheader-dialog" width="286px" :show-close="false" :visible.sync="phoneDialogVisible" :before-close="handleClose">
    <div class="title">更改手机号</div>
    <div class="content">
      <el-input class="input" placeholder="请输入手机号" v-model="phone" clearable></el-input>
      <div class="code-input">
        <el-input placeholder="验证码" v-model="code" @change="submit"></el-input>
        <el-button :disabled="sendMsg ? 'disabled' : false" @click="getCode">{{msg}}</el-button>
      </div>
    </div>
    <div class="footer">
      <el-button class="btn" type="primary" :disabled="disabled" @click="submit">保存</el-button>
      <el-button class="btn" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'PhoneDialog',
  props: ['phoneDialogVisible'],
  data () {
    return {
      phone: '',
      code: '',
      currentTime: 60,
      msg: '获取验证码',
      sendMsg: false
    }
  },
  computed: {
    disabled: function () {
      if (this.phone !== '' && this.code !== '') return false
      return 'disabled'
    }
  },
  methods: {
    getCode () {
      if (this.sendMsg) return
      const { phone } = this
      if (phone === '') {
        this.$message({
          type: 'error',
          message: '请输入手机号'
        })
        return
      }
      this.$axios({
        method: 'post',
        url: this.$urls.getMsgCode,
        data: {
          phone
        }
      })
        .then((response) => {
          if (response.data.state === '11') {
            this.sendMsg = true
            this.countDown()
          }
        })
    },
    countDown () {
      const self = this
      self.msg = `${self.currentTime}s`
      const interval = setInterval(() => {
        self.currentTime -= 1
        self.msg = `${self.currentTime}s`
        if (self.currentTime <= 0) {
          clearInterval(interval)
          self.msg = '获取验证码'
          self.currentTime = 60
          self.sendMsg = false
        }
      }, 1000)
    },
    submit () {
      const { phone } = this
      const { code } = this
      if (phone === '' || code === '') return
      this.$emit('phoneSubmit', phone, code)
    },
    handleClose () {
      this.$emit('phoneDialogClose')
    }
  }
}
</script>

<style scoped lang="scss">
  .title {
    @include font(15px, #999999, center);
    margin-bottom: 20px;
  }
  .content {
    margin-bottom: 30px;
    .code-input {
      @include flex;
      margin-top: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .btn {
      width: 91px;
      height: 30px;
    }
  }
</style>
