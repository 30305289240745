<template>
  <el-dialog class="noheader-dialog" width="250px" :show-close="false" :visible.sync="nameDialogVisible" :before-close="handleClose">
    <div class="title">修改姓名</div>
    <div class="content">
      <el-input class="input" placeholder="请输入姓名" :value="newName" @input="nameInput" clearable></el-input>
    </div>
    <div class="footer">
      <el-button class="btn" type="primary" @click="submit">保存</el-button>
      <el-button class="btn" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'NameDialog',
  props: ['nameDialogVisible', 'name'],
  data () {
    return {
      newName: null
    }
  },
  watch: {
    name: {
      handler: function (val) {
        this.newName = val
      },
      immediate: true
    }
  },
  methods: {
    nameInput (val) {
      this.newName = val
    },
    submit () {
      const {newName: name} = this
      this.$emit('updateName', name)
    },
    handleClose () {
      this.$emit('nameDialogClose')
    }
  }
}
</script>

<style lang="scss">
  .noheader-dialog {
    .el-dialog__header {
      padding: 0;
    }
  }
</style>
<style scoped lang="scss">
  .title {
    @include font(15px, #999999, center);
    margin-bottom: 20px;
  }
  .content {
    margin-bottom: 40px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .btn {
      width: 91px;
      height: 30px;
    }
  }
</style>
